<template>
  <div class="content">
    <div class="content__party">
      <h1 class="title title--big title--color">Университеты карт Газпромбанка</h1>
      <div class="search">
        <v-select
          @input="searchClick"
          v-model="searchResult"
          :filterable="false"
          :options="searchOptions"
          @search="onSearch"
          :get-option-label="getLabel"
          placeholder="Поиск по университетам (Код ГПБ / Название)"
          class="select"
        >
          <template slot="open-indicator">
            <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                 fill="none">
              <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                    stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
          </template>
          <template slot="no-options">Введите свой запрос для поиска</template>
          <template slot="option" slot-scope="option">
            <div class="select__item d-center">
              {{ option.name }} (ID: {{ option.gazprom_id }})
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.name }} (ID: {{ option.gazprom_id }})
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <div class="content__party content__party--start">
      <button @click="addUni" class="button button--light button--no-indent button--circle">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="#2B93E7"></circle><path d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z" fill="white"></path></svg>
        <span>Добавить университет</span>
      </button>
    </div>
    <div class="content__party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <div class="filters filters--indent">
      <div class="filters__block">
        <input
          @change="fetchCards(1, limitSelected.name, '');"
          id="unregion3"
          type="radio"
          name="unregion"
          value=""
          v-model="unregion"
        >
        <label for="unregion3" class="filters__item">Все</label>
        <input
          @change="fetchCards(1, limitSelected.name, true)"
          id="unregion1"
          type="radio"
          name="unregion"
          value="true"
          v-model="unregion"
        >
        <label for="unregion1" class="filters__item">Без региона</label>
        <input
          @change="fetchCards(1, limitSelected.name, false)"
          id="unregion2"
          type="radio"
          name="unregion"
          value="false"
          v-model="unregion"
        >
        <label for="unregion2" class="filters__item">С регионом</label>
      </div>
      <div class="filters__search">
        <v-select
          v-if="unregion !== 'true'"
          @input="fetchCards(1, limitSelected.name, unregion, region)"
          label="region"
          v-model="region"
          :filterable="false"
          :options="regions"
          :reduce="item => item.id"
          @search="onRegionFilterSearch"
          :get-option-label="getLabel"
          placeholder="Выберите регион"
          class="select"
        >
          <template slot="open-indicator">
            <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
              <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
              <path
                d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                fill="#fff"></path>
            </svg>
          </template>
          <template slot="clear-indicator">
            <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
              <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
              <path
                d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                fill="#fff"></path>
            </svg>
          </template>
          <template slot="option" slot-scope="option">
            <div class="select__item">{{ option.name }}</div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="d-center">{{ option.name }}</div>
          </template>
        </v-select>
      </div>
    </div>
    <v-client-table @row-click="rowClick" :data="table.cards" :columns="table.columns" :options="table.options" ref="cardsGPBTable" class="table-default table-default--dynamic">
      <div slot="name" slot-scope="props" class="table-default__left">
        {{ props.row.name }}
      </div>
      <div slot="region" slot-scope="props">
        {{ props.row.region ? props.row.region : '-' }}
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.count > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
    <SidebarRight
      :title="sidebar.title"
      :class="{'sidebar-manage--full': sidebar.show}"
      @close-sidebar="sidebar.show = !sidebar.show"
    >
      <div class="sidebar-card">
        <div class="sidebar-card__bottom">
          <form class="form">
            <div class="form-group">
              <label for="name">Название университета<sup>*</sup></label>
              <div class="form-control" :class="{ 'form-control--error': $v.form.name.$error }">
                <input
                  id="name"
                  type="text"
                  class="form-control__input"
                  name="name"
                  placeholder="Введите название университета"
                  v-model.trim="form.name"
                  @change="$v.form.name.$touch()"
                />
              </div>
              <div class="form-group__error" v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
            </div>
            <div class="form-group">
              <label for="code">Код<sup>*</sup></label>
              <div class="form-control" :class="{ 'form-control--error': $v.form.gazprom_id.$error }">
                <input
                  id="code"
                  type="number"
                  class="form-control__input"
                  name="gazprom_id"
                  placeholder="Введите код университета"
                  v-model.trim="form.gazprom_id"
                  @change="$v.form.gazprom_id.$touch()"
                />
              </div>
              <div class="form-group__error" v-if="$v.form.gazprom_id.$dirty && !$v.form.gazprom_id.required">Обязательное поле</div>
            </div>
            <div class="form-group">
              <label>Регион<sup>*</sup></label>
              <v-select
                label="region"
                v-model="form.region"
                :filterable="false"
                :options="regionOptions"
                :reduce="region => region.id"
                @search="onRegionSearch"
                :get-option-label="getLabel"
                placeholder="Выберите регион"
                class="select"
                :class="{ 'select--error': $v.form.region.$error }"
              >
                <template slot="open-indicator">
                  <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                       fill="none">
                    <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                          stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                  </svg>
                </template>
                <template slot="no-options">Введите регион</template>
                <template slot="option" slot-scope="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
              </v-select>
              <div class="form-group__error" v-if="$v.form.region.$dirty && !$v.form.region.required">Обязательное поле</div>
            </div>
            <button class="button button--stretched" type="button" @click="patchUni" v-if="isEditForm">
              <span>Сохранить изменения</span>
              <img src="@/assets/img/preloader.svg" alt="" class="button__preloader" />
            </button>
            <button class="button button--stretched" type="button" @click="postUni" v-if="!isEditForm">
              <img src="@/assets/img/exchange-icon.svg" alt="">
              <span>Добавить университет</span>
              <img src="@/assets/img/preloader.svg" alt="" class="button__preloader" />
            </button>
          </form>
        </div>
      </div>
    </SidebarRight>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';
import SidebarRight from '@/components/SidebarRight';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {debounce} from 'lodash';

export default {
  name: 'Universities',
  mixins: [ validationMixin ],
  components: { dropdown, SidebarRight },
  data() {
    return {
      form: {
        name: '',
        gazprom_id: null,
        id: null,
        region: '',
      },
      regionOptions: [],
      searchResult: null,
      searchOptions: [],
      isEditForm: false,
      unregion: '',
      region: '',
      regions: [],
      table: {
        cards: [],
        columns: ['id', 'gazprom_id', 'region', 'name'],
        options: {
          headings: {
            id: 'ID',
            gazprom_id: 'Код',
            region: 'Регион',
            name: 'Название'
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback() {
            return 'table-default__row';
          },
          rowAttributesCallback(row) {
            return { 'data-id': row.id };
          }
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: this.$store.state.admin.tableLimit },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
      sidebar: {
        show: false,
        dataSidebar: {},
        title: ''
      }
    };
  },
  mounted() {
    this.limitSelected.name = 20;
    this.fetchCards(1, this.limitSelected.name);
    this.$store.dispatch('admin/GET_REGIONS')
      .then(response => (this.regionOptions = response.data));
    this.fetchRegions();
  },
  validations: {
    form: {
      name: { required },
      gazprom_id: { required },
      region: { required },
    }
  },
  methods: {
    fetchRegions() {
      this.$store.dispatch('admin/GET_REGIONS')
        .then(response => {
          this.regions = response.data;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    fetchCards(page = 1, limit, unregion, region = '') {
      if (!region) region = '';
      if (this.unregion === 'true' && this.region) this.region = '';
      this.$store.dispatch('admin/universities/GET_LIST', { page, limit, unregion, region })
        .then(response => {
          this.table.cards = response.data.results;
          this.pagination.pages = response.data.pages;
          this.pagination.count = response.data.count;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    updateLimiter(data) {
      this.$store.commit('admin/changeTableLimit', data.name);
      this.$refs.cardsGPBTable.setLimit(this.$store.state.admin.tableLimit);
      this.fetchCards(1, this.$store.state.admin.tableLimit, this.unregion);
    },
    clickPaginationCallback(page) {
      this.fetchCards(page, this.$store.state.admin.tableLimit, this.unregion);
    },
    returnStart() {
      return (this.pagination.page * this.table.cards.length) - (this.table.cards.length - 1);
    },
    returnEnd() {
      return this.returnStart() + this.table.cards.length - 1;
    },
    rowClick(row) {
      this.sidebar.title = 'Редактирование университета';
      this.form.name = row.row.name;
      this.form.gazprom_id = row.row.gazprom_id;
      this.form.id = row.row.id;
      this.form.region = row.row.region;
      this.isEditForm = true;
      this.sidebar.show = true;
    },
    addUni() {
      this.form.name = '';
      this.form.gazprom_id = null;
      this.form.region = '';
      this.sidebar.title = 'Добавление университета';
      this.isEditForm = false;
      this.sidebar.show = true;
    },
    patchUni() {
      this.$store.dispatch('admin/universities/PATCH_DATA', this.form)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Данные успешно обновлены'
          });

          this.fetchCards(this.pagination.page, this.limitSelected.name);
          this.sidebar.show = false;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    postUni() {
      this.$store.dispatch('admin/universities/POST_DATA', this.form)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Данные успешно обновлены'
          });

          this.fetchCards(this.pagination.page, this.limitSelected.name);
          this.sidebar.show = false;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    onRegionSearch(search, loading) {
      loading(true);
      this.searchRegion(loading, search, this);
    },
    searchRegion: debounce((loading, search, vm) => {
      vm.$store.dispatch('admin/GET_REGIONS_QUERY', search).then(response => {
        vm.regionOptions = response.data;
        loading(false);
      });
    }, 350),
    onRegionFilterSearch(search, loading) {
      loading(true);
      this.searchFilterRegion(loading, search, this);
    },
    searchFilterRegion: debounce((loading, search, vm) => {
      vm.$store.dispatch('admin/GET_REGIONS_QUERY', search).then(response => {
        vm.regions = response.data;
        loading(false);
      });
    }, 350),
    getLabel(option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    searchClick() {
      this.showSidebar = true;
      this.dataSidebar = this.searchResult;
    },
    onSearch (search, loading) {
      loading(true);
      this.searchDelivery(loading, search, this);
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.$store.dispatch('admin/universities/GET_QUERY', search).then(response => {
        vm.searchOptions = response.data.results;
        loading(false);
      });
    }, 350),
  }
};
</script>
